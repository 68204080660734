import Assets from './Assets';
import Hours from './Hours';
import Tag from './Tag';
import Localization from './Localization';
import OptionGroup from './OptionGroup';
import GroupDependency from './GroupDependency';
import OptionDefault from './OptionDefault';
import {Measurement} from './Measurment';
import {ByType} from './ByType';
import Compound from './Compound';
import {Nutrition} from './Nutritions';

export default class Article {
	// tslint:disable-next-line:variable-name
	_id: string;
	availableHours?: Hours[];
	averageRating?: number;
	icon?: Assets;
	assets?: Assets;
	banner?: Assets;
	defaults?: OptionDefault[];
	recommendations?: OptionDefault[];
	info?: Localization;
	shortDescription?: Localization;
	description?: Localization;
	groups?: OptionGroup[];
	isActive?: boolean;
	name: Localization;
	note?: string;
	number: string;
	counterArticle: string;
	masterId?: string;
	measurement?: Measurement;
	price: {
		$numberDecimal: number;
	};
	basePrice: {
		$numberDecimal: number;
	};
	displayPrice: {
		$numberDecimal: number;
	};
	priceByType: ByType<{$numberDecimal: number}>;
	basePriceByType: ByType<{$numberDecimal: number}>;
	displayPriceByType: ByType<{$numberDecimal: number}>;
	rateable: boolean;
	sortOrder: number;
	tags?: Tag[];
	updatedAt?: Date;
	visible: boolean;
	optionNumber: string;
	isMappedArticle: false;
	groupDependencies: GroupDependency[];
	availability: ByType<boolean>;
	fsk: number;
	requirements: {
		min: number;
		max: number;
	} | null;
	hidden: boolean;
	isFromPrice = false;
	compound?: Compound;
	deposit?: {
		$numberDecimal: number;
	};
	isPiggyLoyalty?: boolean;
	piggyPointsNeeded?: number;
	beforeDiscountPrice?: {
		$numberDecimal: number;
	};
	nutritions?: Nutrition;
	piggyRewardUuid?: string;
}
