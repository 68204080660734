import {PreorderType} from '../enums/PreorderType';

export default class Preorder {
	// tslint:disable-next-line:variable-name
	_id?: string;
	type: PreorderType;
	personCount: number;
	name: string;
	email: string;
	phone: string;
	note: string;
	postalCode: string;
	companyName: string;
	subcard: string;
	floor: string;
	country: string;
	city: string;
	street: string;
	number: string;
	deliveryFee: number | any;
	licenseNumber: string;
	vehicleBrand: string;
	vehicleColor: string;
	lat: number;
	lng: number;
}
